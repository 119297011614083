import { usePage } from '@inertiajs/react'
import User = App.Models.User
import Agency = App.Models.Agency
import Branch = App.Models.Branch
import Agent = App.Models.Agent
import AgentRole = App.Models.AgentRole
import Role = App.Models.Role
import Admin = App.Models.Admin
import AdminRole = App.Models.AdminRole

interface AuthProps {
  user: User | Agent
  role: Role | AgentRole | AdminRole
  admin: Admin
  agency: Agency
  branch: Branch
  userType: 'Agent' | 'User'
}

const useAuth = () => {
  const props = usePage().props

  return props.auth as AuthProps
}

export default useAuth
