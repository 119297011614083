import React, { forwardRef, ReactNode, useEffect, useState } from 'react'
import { createPolymorphicComponent, createStyles, MantineSize, MultiSelect, MultiSelectProps } from '@mantine/core'
import { XelaColor } from '@/XelaReact'

interface XMultiSelectProps extends MultiSelectProps {
  placeholder?: string
  label?: ReactNode
  size?: MantineSize
}

const useStyles = createStyles(
  (
    theme,
    params: {
      value?: string[]
      placeholder?: string
      focused?: boolean
      label?: ReactNode
      size?: MantineSize
      icon?: ReactNode
    }
  ) => ({
    root: {
      fontFamily: 'Nunito Sans',
      position: 'relative',
      width: '100%',
    },

    wrapper: {
      padding: '1.5px !important',
    },

    input: {
      fontSize: '13px',
      fontWeight: 700,
      padding: params.label
        ? params.size === 'sm'
          ? '18px 22px 2px'
          : '26px 22px 6px'
        : params.size === 'sm'
        ? '2px 4px 2px'
        : '6px 12px 6px',
      outline: `1px solid ${XelaColor.Gray11} !important`,
      border: 'none',
      borderRadius: params.size === 'sm' ? '16px' : '18px',
      '&:hover, &:focus': {
        outline: `1.5px solid ${XelaColor.Blue3} !important`,
      },
      '&::placeholder': {
        color: XelaColor.Gray10,
        fontWeight: 300,
      },
    },

    label: {
      fontSize: params.focused || params.placeholder || (params.value && params.value.length > 0) ? '10px' : '13px',
      lineHeight: '15px',
      fontWeight: 600,
      position: 'absolute',
      pointerEvents: 'none',
      paddingLeft: '24px',
      transition: 'all 300ms ease',
      paddingTop: params.label
        ? `${
            params.focused || params.placeholder || (params.value && params.value.length > 0)
              ? params.size === 'sm'
                ? '8px'
                : '16px'
              : params.size === 'sm'
              ? '22px'
              : '30px'
          }`
        : '0px',
      zIndex: 1,
      color: XelaColor.Gray3,
      '&:hover, &:focus': {
        paddingLeft: '25px',
      },
    },

    dropdown: {
      fontFamily: 'Nunito Sans',
      boxShadow: '0px 8px 12px rgba(0, 0, 0, 0.08), 0px 4px 48px rgba(0, 0, 0, 0.08)',
      borderRadius: '12px',
      padding: '8px !important',
      zIndex: 9999,
    },

    value: {
      backgroundColor: XelaColor.Blue12,
      color: XelaColor.Blue3,
      borderRadius: '12px',
      height: '24px',
    },

    defaultValueLabel: {
      fontFamily: 'Nunito Sans',
      fontSize: '13px',
      fontWeight: 700,
    },

    defaultValueRemove: {
      fontWeight: 700,
      color: XelaColor.Blue3,
      margin: '0 4px',
    },

    item: {
      width: '90%',
      fontSize: '14px',
      fontWeight: 700,
      color: '#1D1929',
      marginBottom: '4px !important',
      padding: params.size === 'sm' ? '8px 12px !important' : '12px !important',
      '&[data-selected]': {
        '&, &:hover': {
          color: '#FFFFFF',
          background: '#003CFF !important',
          borderRadius: '12px',
        },
      },
      '&[data-focused]': {
        '&, &:hover': {
          background: '#F9F9F9',
          borderRadius: '12px',
        },
      },
      '&:hover': {
        borderRadius: '12px',
      },
    },
  })
)

// eslint-disable-next-line react/display-name
const _XMultiSelect = forwardRef<HTMLInputElement, XMultiSelectProps>(({ children, ...props }, ref) => {
  const [val, setVal] = useState(props.value)
  const [focused, setFocused] = useState(false)

  const { classes } = useStyles({
    label: props.label,
    placeholder: props.placeholder,
    icon: props.icon,
    value: val,
    focused,
  })

  useEffect(() => {
    setVal(props.value)
  }, [props.value])

  return (
    <MultiSelect
      ref={ref}
      {...props}
      classNames={classes}
      value={val}
      onFocus={() => {
        setFocused(true)
      }}
      onBlur={() => {
        setFocused(false)
      }}
      onChange={(value) => {
        if (props.onChange) {
          props.onChange(value)
        }

        setVal(value)
      }}
    >
      {children}
    </MultiSelect>
  )
})
export const XMultiSelect = createPolymorphicComponent<'input', XMultiSelectProps>(_XMultiSelect)

export default XMultiSelect
