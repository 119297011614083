import React, { useState } from 'react'
import { Button, Divider, HStack, Typography, VStack, XelaColor } from '@/XelaReact'
import { Box, LoadingOverlay, Modal } from '@mantine/core'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/Store'
import { router, usePage } from '@inertiajs/react'
import axios from 'axios'
import { useClipboard } from '@mantine/hooks'
import { IconCopy } from '@tabler/icons-react'
import { updateShowB2BAgentPasswordResetModal } from '@/Store/B2B/b2bAgentSlice'

const B2BAgentPasswordResetModal = () => {
  const page = usePage()
  const clipboard = useClipboard({ timeout: 500 })
  const dispatch = useDispatch()
  const b2bAgentStore = useSelector((state: RootState) => state.b2bAgent)
  const [loading, setLoading] = useState(false)
  const [newPassword, setNewPassword] = useState<string | null>(null)
  const [isCopied, setIsCopied] = useState(false)

  const onClose = () => {
    dispatch(
      updateShowB2BAgentPasswordResetModal({
        show: false,
        agent_id: null,
        agent: null,
      })
    )

    setNewPassword(null)
    router.visit(page.url, { preserveScroll: true })
  }

  return (
    <Modal
      centered
      overlayProps={{
        color: '#000',
        opacity: 0.55,
        blur: 3,
      }}
      closeOnEscape={false}
      closeOnClickOutside={false}
      withCloseButton={false}
      trapFocus={false}
      radius={16}
      size={750}
      opened={b2bAgentStore.show_b2b_agent_password_reset_modal}
      onClose={() => {
        onClose()
      }}
    >
      <VStack spacing="12px">
        <HStack>
          <Typography variant={'subheadline'}>Reset Password</Typography>
        </HStack>
        <HStack>
          <Divider variant="dotted"></Divider>
        </HStack>
        <VStack
          spacing="12px"
          style={{
            position: 'relative',
            minHeight: '250px',
          }}
        >
          <LoadingOverlay visible={loading} overlayBlur={2} loaderProps={{ color: 'blueX' }} />
          <VStack
            alignItems="flex-start"
            spacing="12px"
            style={{
              flexGrow: 1,
            }}
          >
            {newPassword === null && (
              <HStack>
                <Typography variant={'body-small'} color={XelaColor.Gray6}>
                  Are you sure you want to reset the password of{' '}
                  <Typography variant={'body-small'} color={XelaColor.Blue3}>
                    {b2bAgentStore.b2b_selected_agent?.name}
                  </Typography>
                  ?
                </Typography>
              </HStack>
            )}
            {newPassword !== null && (
              <VStack spacing="12px" alignItems="flex-start">
                <HStack>
                  <Typography variant={'body-small'} color={XelaColor.Gray6}>
                    The new password for{' '}
                    <Typography variant={'body-small'} color={XelaColor.Blue3}>
                      {b2bAgentStore.b2b_selected_agent?.name}
                    </Typography>{' '}
                  </Typography>
                </HStack>
                <HStack spacing="12px">
                  <Box
                    style={{
                      backgroundColor: XelaColor.Blue12,
                      padding: '12px 16px',
                      borderRadius: '12px',
                    }}
                  >
                    <Typography variant={'subheadline'} color={XelaColor.Blue3}>
                      {newPassword}
                    </Typography>
                  </Box>
                  {!isCopied && (
                    <Box
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        clipboard.copy(newPassword)
                        setIsCopied(true)
                        setTimeout(() => {
                          setIsCopied(false)
                        }, 2000)
                      }}
                    >
                      <IconCopy color={XelaColor.Blue3} stroke={1.2} size={20}></IconCopy>
                    </Box>
                  )}
                  {isCopied && (
                    <Typography variant="body-small" color={XelaColor.Blue3}>
                      Copied!
                    </Typography>
                  )}
                </HStack>
              </VStack>
            )}
          </VStack>
          <HStack>
            <Divider variant="dotted"></Divider>
          </HStack>
          {newPassword === null && (
            <HStack spacing="12px" justifyContent="flex-end" style={{ marginBottom: '5px' }}>
              <Button
                label={'Cancel'}
                variant="secondary"
                onClick={() => {
                  onClose()
                }}
              ></Button>
              <Button
                label={'Confirm'}
                onClick={() => {
                  setLoading(true)

                  axios
                    .post(
                      route('b2b.agents.reset-password', {
                        id: b2bAgentStore.b2b_agent_id,
                      })
                    )
                    .then((response) => {
                      if (response.data.success) {
                        setNewPassword(response.data.data.password)
                        setLoading(false)
                      }
                    })
                    .finally(() => {
                      setLoading(false)
                    })
                }}
              ></Button>
            </HStack>
          )}
          {newPassword && (
            <HStack spacing="12px" justifyContent="flex-end" style={{ marginBottom: '5px' }}>
              <Button
                label={'Done'}
                variant="primary"
                onClick={() => {
                  onClose()
                }}
              ></Button>
            </HStack>
          )}
        </VStack>
      </VStack>
    </Modal>
  )
}

export default B2BAgentPasswordResetModal
