import React, { FC } from 'react'
import { HStack, Typography, XelaColor } from '@/XelaReact'
import { router } from '@inertiajs/react'
import Notification = App.Models.Notification

const CounsellorAssignedNotification: FC<{
  notification: Notification
  portal?: string
}> = ({ notification }) => {
  if (notification.data.event_by) {
    return (
      <HStack
        spacing="3px"
        style={{
          flexWrap: 'wrap',
          cursor: 'pointer',
          padding: '12px',
        }}
        onClick={() => {
          router.visit(
            route('leads.id', {
              id: notification.data.lead_id,
            })
          )
        }}
      >
        <Typography variant="body-small-bold">{notification.data.event_by}</Typography>
        <Typography variant="body-small" color={XelaColor.Gray6}>
          assigned you a new lead
        </Typography>
        <Typography variant="body-small-bold" color={XelaColor.Orange3}>
          #{notification.data.lead_name}
        </Typography>
      </HStack>
    )
  }

  return (
    <HStack
      spacing="3px"
      style={{
        flexWrap: 'wrap',
        cursor: 'pointer',
        padding: '12px',
      }}
      onClick={() => {
        router.visit(
          route('leads.id', {
            id: notification.data.lead_id,
          })
        )
      }}
    >
      <Typography variant="body-small" color={XelaColor.Gray6}>
        New Lead
      </Typography>
      <Typography variant="body-small-bold" color={XelaColor.Orange3}>
        #{notification.data.lead_name}
      </Typography>
      <Typography variant="body-small" color={XelaColor.Gray6}>
        has been assigned to You
      </Typography>
    </HStack>
  )
}

export default CounsellorAssignedNotification
