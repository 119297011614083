import React, { FC, useEffect, useState } from 'react'
import { Button, Divider, HStack, Radio, RadioButton, Typography, VStack, XelaColor } from '@/XelaReact'
import { Box, Drawer, LoadingOverlay } from '@mantine/core'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/Store'
import { useForm } from '@inertiajs/react'
import { updateDocumentBelongsTo, updateShowUploadDocumentModal } from '@/Store/leadSlice'
import FileUpload from '@/XelaReact/FileUpload/FileUpload'
import { IconX } from '@tabler/icons-react'
import XSelect from '@/Mantine/XSelect'
import XTextInput from '@/Mantine/XTextInput'
import {XTextarea} from "@/Mantine/XTextarea";
import axios from 'axios'
import collect from 'collect.js'
import { DocumentTypeProps } from '@/Types/DocumentTypes'

const UPLOAD_TYPES = [
  {
    name: 'single_upload',
    label: 'Single Upload',
  },
  {
    name: 'bulk_upload',
    label: 'Bulk Upload',
  },
]

const UploadDocumentsModal: FC<{
  portal?: string
}> = ({ portal = 'Agency' }) => {
  const dispatch = useDispatch()
  const leadStore = useSelector((state: RootState) => state.lead)
  const [loading, setLoading] = useState(false)
  const [uploadType, setUploadType] = useState('single_upload')
  const [leadId, setLeadId] = useState<string | null>(null)
  const [documentTypes, setDocumentTypes] = useState<DocumentTypeProps[]>([])

  const { data, setData, post, errors, reset, recentlySuccessful } = useForm<{
    document: File | null
    name: string
    group: string
    type: string
    others_type: string
    entity: string
    entity_id: string | null
    lead_id: string | null
    belongs_to: string
    documents: File[],
    comments: string,
  }>({
    document: null,
    name: '',
    group: '',
    type: '',
    belongs_to: 'Applicant',
    others_type: '',
    entity: 'Lead',
    entity_id: null,
    lead_id: null,
    documents: [],
    comments: '',
  })

  useEffect(() => {
    axios
      .post<DocumentTypeProps[]>('/select/meta-options', {
        entity_name: 'documents',
        column_name: 'lead_documents',
        with_group: true,
      })
      .then((response) => {
        setDocumentTypes(response.data)
      })
      .catch((error) => {
        console.error('Error fetching documents types:', error)
      })
  }, [])

  useEffect(() => {
    if (recentlySuccessful) {
      dispatch(
        updateShowUploadDocumentModal({
          show: false,
          document_entity: '',
          document_entity_id: null,
          lead_id: null,
          belongs_to: '',
        })
      )
      reset()
      setUploadType('single_upload')
    }
  }, [recentlySuccessful])

  useEffect(() => {
    if (leadStore.show_upload_document_modal) {
      setLeadId(leadStore.lead_id)

      setData((prev) => ({
        ...prev,
        lead_id: leadStore.lead_id,
        entity: leadStore.document_entity,
        entity_id: leadStore.document_entity_id,
        belongs_to: leadStore.document_belongs_to,
      }))
    }
  }, [leadStore.show_upload_document_modal])

  return (
    <Drawer
      overlayProps={{
        color: '#000',
        opacity: 0.55,
        blur: 3,
      }}
      position={'right'}
      closeOnEscape={false}
      closeOnClickOutside={false}
      withCloseButton={false}
      trapFocus={false}
      size={700}
      opened={leadStore.show_upload_document_modal}
      onClose={() => {
        dispatch(
          updateShowUploadDocumentModal({
            show: false,
            document_entity: '',
            document_entity_id: null,
            lead_id: null,
            belongs_to: '',
          })
        )
        reset()
        setUploadType('single_upload')
      }}
      styles={{
        body: {
          height: '100%',
        },
      }}
    >
      <VStack spacing="12px" height={'100%'}>
        <HStack justifyContent="space-between">
          <HStack>
            <Typography variant={'subheadline'}>Upload Documents</Typography>
          </HStack>
          <HStack width="50%" justifyContent="flex-end">
            <XSelect
              value={data.belongs_to}
              size={'sm'}
              onInit={(callback) => {
                axios
                  .post(portal == 'Hub' ? '/select/meta-options' : '/select/meta-options', {
                    entity_name: 'leads',
                    column_name: 'document_upload_belongs_to',
                  })
                  .then((response) => {
                    callback(response.data)
                  })
                  .catch((error) => {
                    console.log(error)
                    callback([])
                  })
              }}
              onChange={(value) => {
                if (value) {
                  setData((prevData) => ({
                    ...prevData,
                    belongs_to: value,
                  }))
                  dispatch(updateDocumentBelongsTo(value))
                }
              }}
            ></XSelect>
          </HStack>
        </HStack>
        <VStack
          className={'pr-2 overflow-y-auto'}
          spacing={'12px'}
          style={{
            flex: '1 1 0',
            height: 0,
          }}
        >
          <LoadingOverlay visible={loading} overlayBlur={2} loaderProps={{ color: 'blueX' }} />
          <VStack spacing="12px">
            <HStack spacing="12px">
              <RadioButton
                checkedValue={uploadType}
                direction="horizontal"
                border={true}
                size={'small'}
                onChange={(e) => {
                  const target: HTMLInputElement = e.target as HTMLInputElement
                  if (target) {
                    if (target.value === 'bulk_upload') {
                      setData((prevData) => ({
                        ...prevData,
                        document: null,
                        name: '',
                        group: '',
                        type: '',
                        others_type: '',
                        documents: [],
                        comments: ''
                      }))
                    } else {
                      setData((prevData) => ({
                        ...prevData,
                        documents: [],
                      }))
                    }

                    setUploadType(target.value)
                  }
                }}
                radios={UPLOAD_TYPES.map((selection) => new Radio(selection.name, selection.label))}
                name={'upload_radios'}
              />
            </HStack>
            <HStack>
              <Divider variant={'dotted'}></Divider>
            </HStack>
            <HStack spacing="12px" style={{ minHeight: '240px' }}>
              {uploadType === 'single_upload' && (
                <HStack spacing="12px" alignItems="flex-start">
                  <HStack>
                    <FileUpload
                      error={errors['document'] !== undefined}
                      helperText={errors['document']}
                      value={data.document}
                      typeUploadText="PNG, JPG, PDF, DOCX, XLSX (max. 100 MB)"
                      options={{
                        onDrop: (acceptedFiles: File[]) => {
                          if (acceptedFiles.length > 0) {
                            setData((prevData) => ({
                              ...prevData,
                              document: acceptedFiles[0],
                              name: acceptedFiles[0].name,
                            }))
                          }
                        },
                      }}
                    ></FileUpload>
                  </HStack>
                  <VStack spacing="12px" alignItems="flex-end">
                    <XTextInput
                      label="Name"
                      value={data.name}
                      error={errors['name']}
                      onChange={(e) => {
                        if (e.target) {
                          setData((prevData) => ({
                            ...prevData,
                            name: e.target.value,
                          }))
                        }
                      }}
                    ></XTextInput>
                    <XSelect
                      searchable
                      label={'Type'}
                      error={errors.type}
                      value={data.type}
                      data={documentTypes}
                      onChange={(value) => {
                        if (value) {
                          const datum = collect(documentTypes).where('value', value).first()
                          if (datum) {
                            setData((prevData) => ({
                              ...prevData,
                              group: datum.group,
                              type: value,
                            }))
                          }
                        }
                      }}
                    />

                    {data.type === 'Others' && (
                      <XTextInput
                        label="Others Type"
                        error={errors['others_type']}
                        value={data.others_type}
                        onChange={(e) => {
                          if (e.target) {
                            setData((prevData) => ({
                              ...prevData,
                              others_type: e.target.value,
                            }))
                          }
                        }}
                      ></XTextInput>
                    )}
                    <XTextarea
                      label="Comments"
                      value={data.comments}
                      error={errors['comments']}
                      onChange={(e) => {
                        if (e.target) {
                          setData((prevData) => ({
                            ...prevData,
                            comments: e.target.value,
                          }))
                        }
                      }}
                    ></XTextarea>
                  </VStack>
                </HStack>
              )}

              {uploadType === 'bulk_upload' && (
                <VStack>
                  <HStack>
                    <FileUpload
                      height="200px"
                      error={Object.values(errors).length > 0}
                      helperText={Object.values(errors).join(', <br/>')}
                      typeUploadText="PDF,JPG,PNG,WORD,XLSX (max. 100 MB)"
                      options={{
                        onDrop: (acceptedFiles: File[]) => {
                          if (acceptedFiles.length > 0) {
                            setData((prev) => ({
                              ...prev,
                              documents: [...prev.documents, ...acceptedFiles],
                            }))
                          }
                        },
                      }}
                      value={null}
                    ></FileUpload>
                  </HStack>
                  <HStack>
                     <XTextarea
                      label="Comments"
                      value={data.comments}
                      error={errors['comments']}
                      onChange={(e) => {
                        if (e.target) {
                          setData((prevData) => ({
                            ...prevData,
                            comments: e.target.value,
                          }))
                        }
                      }}
                    ></XTextarea>
                  </HStack>
                  {data.documents.length > 0 && (
                    <>
                      <HStack>
                        <Divider variant={'dotted'}></Divider>
                      </HStack>
                      <VStack
                        spacing="4px"
                        style={{
                          height: '150px',
                          overflowY: 'auto',
                          padding: '4px',
                        }}
                      >
                        {data.documents.map((file, index) => (
                          <HStack key={index} justifyContent="space-between">
                            <Typography variant={'body-small-bold'} style={{ width: '400px', wordBreak: 'break-all' }}>
                              {index + 1}. {file.name}
                            </Typography>
                            <Box
                              style={{
                                padding: '4px',
                                cursor: 'pointer',
                              }}
                            >
                              <IconX
                                size={16}
                                color={XelaColor.Red6}
                                onClick={() => {
                                  setData((prev) => ({
                                    ...prev,
                                    documents: prev.documents.filter((item, i) => i !== index),
                                  }))
                                }}
                              />
                            </Box>
                          </HStack>
                        ))}
                      </VStack>
                    </>
                  )}
                </VStack>
              )}
            </HStack>
          </VStack>
        </VStack>
        <HStack>
          <Divider variant="dotted"></Divider>
        </HStack>
        {uploadType === 'single_upload' && (
          <HStack spacing="12px" justifyContent="flex-end">
            <Button
              style={{ width: '80px' }}
              label={'Cancel'}
              variant={'secondary'}
              onClick={() => {
                dispatch(
                  updateShowUploadDocumentModal({
                    show: false,
                    document_entity: '',
                    document_entity_id: null,
                    lead_id: null,
                    belongs_to: '',
                  })
                )
                reset()
                setUploadType('single_upload')
              }}
            ></Button>
            <Button
              disabled={leadId === null}
              style={{ width: '80px' }}
              label="Upload"
              onClick={() => {
                post(`/leads/${leadId}/document`)
              }}
            ></Button>
          </HStack>
        )}
        {uploadType === 'bulk_upload' && (
          <HStack spacing={'12px'} justifyContent="flex-end">
            <Button
              style={{ width: '80px' }}
              label={'Cancel'}
              variant={'secondary'}
              onClick={() => {
                dispatch(
                  updateShowUploadDocumentModal({
                    show: false,
                    document_entity: '',
                    document_entity_id: null,
                    lead_id: null,
                    belongs_to: '',
                  })
                )
                reset()
                setUploadType('single_upload')
              }}
            ></Button>
            <Button
              style={{ width: '80px' }}
              label={'Import'}
              onClick={() => {
                setLoading(true)
                post('/documents/bulk-upload', {
                  preserveState: true,
                  preserveScroll: true,
                  onFinish: () => {
                    setLoading(false)
                  },
                })
              }}
            ></Button>
          </HStack>
        )}
      </VStack>
    </Drawer>
  )
}

export default UploadDocumentsModal
