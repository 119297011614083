import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import VisitorLead = App.Models.VisitorLead
import Document = App.Models.Document

export interface VisitorLeadState {
  visitor_lead_id: string | null
  visitor_lead: VisitorLead | null
  show_edit_personal_detail_modal: boolean
  show_edit_visa_passport_detail_modal: boolean
  show_edit_travel_detail_modal: boolean
  show_edit_family_detail_modal: boolean
  show_edit_income_detail_modal: boolean
  show_edit_work_experience_detail_modal: boolean
  show_visitor_lead_assigned_transactor_modal: boolean
  branch_id: string | null
  portal: string
  show_upload_document_modal: boolean
  document_belongs_to: string
  document_entity: string
  document_entity_id: string | null
  download_documents: Document[] | null
  show_document_download_modal: boolean
  lead_manager_id: string | null
  lead_manager_id_one: string | null
  lead_manager_id_two: string | null
  lead_manager_id_three: string | null
  visa_manager_id: string | null
  is_lead_manager_changed: boolean
  is_visa_manager_changed: boolean
  show_visitor_lead_import_modal: boolean
  show_lead_convert_modal: boolean
  lead_type: string | null
  is_cold: boolean
  cold_reason: string
  cold_status: string
  reload_all_leads: boolean
  current_leads_filters: {
    branch_id?: string
    lead_manager_id?: string
    lead_source?: string
    created_at?: string
    from_date?: string
    to_date?: string
    service_types?: string[]
    sub_lead_source?: string
    state?: string
    city?: string
  }
  previous_leads_filters: {
    branch_id?: string
    lead_manager_id?: string
    lead_source?: string
    created_at?: string
    from_date?: string
    to_date?: string
    service_type?: string[]
    sub_lead_source?: string
    state?: string
    city?: string
  }
  show_all_leads_filter_drawer: boolean
  selected_lead_ids: string[]
  selected_leads: VisitorLead[]
  quick_action_show_lead_manager_modal: boolean
  show_branch_change_modal: boolean
  task_counsellor_id: string | null
  follow_up_counsellor_id: string | null
  show_insurance_confirm_details_modal: boolean
  document: Document | null
  show_edit_upload_document_modal: boolean
  is_leads_notes_opened: boolean
  selected_visitor_lead_id: number | null
}

const initialState: VisitorLeadState = {
  visitor_lead_id: null,
  visitor_lead: null,
  show_edit_personal_detail_modal: false,
  show_edit_visa_passport_detail_modal: false,
  show_edit_travel_detail_modal: false,
  show_edit_family_detail_modal: false,
  show_edit_income_detail_modal: false,
  show_edit_work_experience_detail_modal: false,
  show_visitor_lead_assigned_transactor_modal: false,
  branch_id: null,
  portal: 'Agency',
  show_upload_document_modal: false,
  document_belongs_to: 'Applicant',
  document_entity: 'Visitor Lead',
  document_entity_id: null,
  download_documents: null,
  show_document_download_modal: false,
  lead_manager_id: null,
  lead_manager_id_one: null,
  lead_manager_id_two: null,
  lead_manager_id_three: null,
  visa_manager_id: null,
  is_lead_manager_changed: false,
  is_visa_manager_changed: false,
  show_visitor_lead_import_modal: false,
  show_lead_convert_modal: false,
  lead_type: null,
  is_cold: false,
  cold_reason: '',
  cold_status: '',
  current_leads_filters: {
    branch_id: '',
    lead_manager_id: '',
    lead_source: '',
    created_at: '',
    from_date: '',
    to_date: '',
    service_types: [],
    sub_lead_source: '',
    state: '',
    city: '',
  },
  previous_leads_filters: {
    branch_id: '',
    lead_manager_id: '',
    lead_source: '',
    created_at: '',
    from_date: '',
    to_date: '',
    service_type: [],
    sub_lead_source: '',
    state: '',
    city: '',
  },
  reload_all_leads: false,
  show_all_leads_filter_drawer: false,
  selected_lead_ids: [],
  selected_leads: [],
  quick_action_show_lead_manager_modal: false,
  show_branch_change_modal: false,
  task_counsellor_id: null,
  follow_up_counsellor_id: null,
  show_insurance_confirm_details_modal: false,
  document: null,
  show_edit_upload_document_modal: false,
  is_leads_notes_opened: false,
  selected_visitor_lead_id: null,
}

export const visitorLeadSlice = createSlice({
  name: 'visitorLeadSlice',
  initialState,
  reducers: {
    updateVisitorLeadId(state, action: PayloadAction<string | null>) {
      state.visitor_lead_id = action.payload
    },
    updateVisitorLead(state, action: PayloadAction<VisitorLead | null>) {
      state.visitor_lead = action.payload
    },
    updateShowEditPersonalDetailModal(
      state,
      action: PayloadAction<{ show: boolean; visitorLead: VisitorLead | null }>
    ) {
      state.show_edit_personal_detail_modal = action.payload.show
      state.visitor_lead = action.payload.visitorLead
    },
    updateShowEditVisaPassportDetailModal(
      state,
      action: PayloadAction<{ show: boolean; visitorLead: VisitorLead | null }>
    ) {
      state.show_edit_visa_passport_detail_modal = action.payload.show
      state.visitor_lead = action.payload.visitorLead
    },
    updateShowEditTravelDetailModal(
      state,
      action: PayloadAction<{
        show: boolean
        visitorLead: VisitorLead | null
      }>
    ) {
      state.show_edit_travel_detail_modal = action.payload.show
      state.visitor_lead = action.payload.visitorLead
    },
    updateShowEditFamilyDetailModal(
      state,
      action: PayloadAction<{
        show: boolean
        visitorLead: VisitorLead | null
      }>
    ) {
      state.show_edit_family_detail_modal = action.payload.show
      state.visitor_lead = action.payload.visitorLead
    },
    updateShowEditIncomeDetailModal(
      state,
      action: PayloadAction<{
        show: boolean
        visitorLead: VisitorLead | null
      }>
    ) {
      state.show_edit_income_detail_modal = action.payload.show
      state.visitor_lead = action.payload.visitorLead
    },
    updateShowEditWorkExperienceDetailModal(
      state,
      action: PayloadAction<{
        show: boolean
        visitorLead: VisitorLead | null
      }>
    ) {
      state.show_edit_work_experience_detail_modal = action.payload.show
      state.visitor_lead = action.payload.visitorLead
    },
    updateVisitorLeadBranchId(state, action: PayloadAction<string | null>) {
      state.branch_id = action.payload
    },
    updateDocumentBelongsTo(state, action: PayloadAction<string>) {
      state.document_belongs_to = action.payload
    },
    updateShowUploadDocumentModal(
      state,
      action: PayloadAction<{
        show: boolean
        document_entity: string
        document_entity_id: string | null
        visitor_lead_id: string | null
        belongs_to: string
      }>
    ) {
      state.show_upload_document_modal = action.payload.show
      state.document_entity = action.payload.document_entity
      state.document_entity_id = action.payload.document_entity_id
      state.visitor_lead_id = action.payload.visitor_lead_id
      state.document_belongs_to = action.payload.belongs_to
    },
    updateDownloadDocumentsModal(
      state,
      action: PayloadAction<{
        show: boolean
        visitor_lead_id: string | null
        documents: Document[] | null
      }>
    ) {
      state.show_document_download_modal = action.payload.show
      state.visitor_lead_id = action.payload.visitor_lead_id
      state.download_documents = action.payload.documents
    },
    updateIsLeadManagerChanged(
      state,
      action: PayloadAction<{
        show: boolean
        /*visitor_lead_id: string | null
        lead_manager_id: string | null
        lead_manager_id_one?: string | null
        lead_manager_id_two?: string | null
        lead_manager_id_three?: string | null
        branch_id: string | null*/
        visitor_lead: VisitorLead | null
      }>
    ) {
      state.is_lead_manager_changed = action.payload.show
      /*state.visitor_lead_id = action.payload.visitor_lead_id
      state.lead_manager_id = action.payload.lead_manager_id
      state.lead_manager_id_one = action.payload.lead_manager_id_one || null
      state.lead_manager_id_two = action.payload.lead_manager_id_two || null
      state.lead_manager_id_three = action.payload.lead_manager_id_three || null
      state.branch_id = action.payload.branch_id*/
      state.visitor_lead = action.payload.visitor_lead
    },
    updateIsVisaManagerChanged(
      state,
      action: PayloadAction<{
        show: boolean
        branch_id: string | null
        visitor_lead_id: string | null
        visa_manager_id: string | null
      }>
    ) {
      state.is_visa_manager_changed = action.payload.show
      state.branch_id = action.payload.branch_id
      state.visitor_lead_id = action.payload.visitor_lead_id
      state.visa_manager_id = action.payload.visa_manager_id
    },
    updateShowVisitorLeadAssignedTransactorModal(
      state,
      action: PayloadAction<{
        show: boolean
        branch_id: string | null
        visitor_lead_id: string | null
      }>
    ) {
      state.show_visitor_lead_assigned_transactor_modal = action.payload.show
      state.branch_id = action.payload.branch_id
      state.visitor_lead_id = action.payload.visitor_lead_id
    },
    updateShowVisitorLeadImportModal(state, action: PayloadAction<boolean>) {
      state.show_visitor_lead_import_modal = action.payload
    },
    updateShowLeadConvertModal(
      state,
      action: PayloadAction<{
        show: boolean
        branch_id: string | null
        visitor_lead_id: string | null
        lead_type: string | null
      }>
    ) {
      state.show_lead_convert_modal = action.payload.show
      state.branch_id = action.payload.branch_id
      state.visitor_lead_id = action.payload.visitor_lead_id
      state.lead_type = action.payload.lead_type
    },
    updateIsCold(state, action: PayloadAction<boolean>) {
      state.is_cold = action.payload
    },
    updateColdLeadStatus(state, action: PayloadAction<string>) {
      state.cold_status = action.payload
    },
    updateColdLeadReason(state, action: PayloadAction<string>) {
      state.cold_reason = action.payload
    },
    showAllLeadFilterDrawer(state) {
      state.show_all_leads_filter_drawer = true
    },
    hideAllLeadFilterDrawer(state) {
      state.show_all_leads_filter_drawer = false
    },
    reloadAllLeads(state, action: PayloadAction<boolean>) {
      state.reload_all_leads = action.payload
    },
    updateCurrentLeadFilters(
      state,
      action: PayloadAction<{
        branch_id?: string
        lead_manager_id?: string
        lead_source?: string
        created_at?: string
        from_date?: string
        to_date?: string
        service_types?: string[]
        sub_lead_source?: string
        state?: string
        city?: string
      }>
    ) {
      state.current_leads_filters = action.payload
    },
    updatePreviousLeadFilters(
      state,
      action: PayloadAction<{
        branch_id?: string
        lead_manager_id?: string
        lead_source?: string
        created_at?: string
        from_date?: string
        to_date?: string
        service_types?: string[]
        sub_lead_source?: string
        state?: string
        city?: string
      }>
    ) {
      state.previous_leads_filters = action.payload
    },
    updateSelectedLeadIds(state, action: PayloadAction<string[]>) {
      state.selected_lead_ids = action.payload
    },
    updateSelectedLeads(state, action: PayloadAction<VisitorLead[]>) {
      state.selected_leads = action.payload
    },
    updateQuickActionShowLeadManagerModal(
      state,
      action: PayloadAction<{
        show: boolean
        branch_id: string | null
      }>
    ) {
      state.quick_action_show_lead_manager_modal = action.payload.show
      state.branch_id = action.payload.branch_id
    },
    updateVisitorLeadShowBranchChangeModal(
      state,
      action: PayloadAction<{
        show: boolean
        branch_id: string | null
        visitor_lead_id: string | null
        lead_manager_id: string | null
        visa_manager_id: string | null
        task_counsellor_id: string | null
        follow_up_counsellor_id: string | null
      }>
    ) {
      state.show_branch_change_modal = action.payload.show
      state.branch_id = action.payload.branch_id
      state.visitor_lead_id = action.payload.visitor_lead_id
      state.lead_manager_id = action.payload.lead_manager_id
      state.visa_manager_id = action.payload.visa_manager_id
      state.task_counsellor_id = action.payload.task_counsellor_id
      state.follow_up_counsellor_id = action.payload.follow_up_counsellor_id
    },
    updateShowInsuranceConfirmDetailsModal(
      state,
      action: PayloadAction<{
        show: boolean
        visitor_lead: VisitorLead | null
      }>
    ) {
      state.show_insurance_confirm_details_modal = action.payload.show
      state.visitor_lead = action.payload.visitor_lead
    },
    updateShowEditUploadDocumentModal(
      state,
      action: PayloadAction<{
        show: boolean
        document_entity: string
        document_entity_id: string | null
        visitor_lead_id: string | null
        belongs_to: string
        document: Document | null
      }>
    ) {
      state.show_edit_upload_document_modal = action.payload.show
      state.document_entity = action.payload.document_entity
      state.document_entity_id = action.payload.document_entity_id
      state.visitor_lead_id = action.payload.visitor_lead_id
      ;(state.document_belongs_to = action.payload.belongs_to), (state.document = action.payload.document)
    },
    updateIsVisitorLeadsNotesOpened(
      state,
      action: PayloadAction<{ selected_visitor_lead_id: number | null; is_leads_notes_opened: boolean }>
    ) {
      state.selected_visitor_lead_id = action.payload.selected_visitor_lead_id
      state.is_leads_notes_opened = action.payload.is_leads_notes_opened
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  updateVisitorLeadId,
  updateVisitorLead,
  updateShowEditPersonalDetailModal,
  updateShowEditVisaPassportDetailModal,
  updateShowEditTravelDetailModal,
  updateShowEditFamilyDetailModal,
  updateShowEditIncomeDetailModal,
  updateShowEditWorkExperienceDetailModal,
  updateVisitorLeadBranchId,
  updateShowUploadDocumentModal,
  updateDocumentBelongsTo,
  updateDownloadDocumentsModal,
  updateIsLeadManagerChanged,
  updateIsVisaManagerChanged,
  updateShowVisitorLeadAssignedTransactorModal,
  updateShowVisitorLeadImportModal,
  updateShowLeadConvertModal,
  updateIsCold,
  updateColdLeadStatus,
  updateColdLeadReason,
  showAllLeadFilterDrawer,
  hideAllLeadFilterDrawer,
  reloadAllLeads,
  updateCurrentLeadFilters,
  updatePreviousLeadFilters,
  updateSelectedLeadIds,
  updateSelectedLeads,
  updateQuickActionShowLeadManagerModal,
  updateVisitorLeadShowBranchChangeModal,
  updateShowInsuranceConfirmDetailsModal,
  updateShowEditUploadDocumentModal,
  updateIsVisitorLeadsNotesOpened,
} = visitorLeadSlice.actions

export default visitorLeadSlice.reducer
