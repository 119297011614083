import React, { FC } from 'react'
import { HStack, Typography, XelaColor } from '@/XelaReact'
import { router } from '@inertiajs/react'
import Notification = App.Models.Notification

const VisaGrantedNotification: FC<{
  notification: Notification
  portal?: string
}> = ({ notification, portal = 'Agency' }) => {
  return (
    <HStack
      spacing="3px"
      style={{
        flexWrap: 'wrap',
        cursor: 'pointer',
        padding: '12px',
      }}
      onClick={() => {
        if (portal === 'Agency') {
          router.visit(`/visas/${notification.data.visa_id}`)
        } else {
          if (portal === 'Hub') {
            router.visit(`/leads/${notification.data.lead_id}`)
          }
        }
      }}
    >
      <Typography variant="body-small" color={XelaColor.Gray6}>
        Visa has been granted for lead
      </Typography>
      <Typography variant="body-small-bold" color={XelaColor.Orange3}>
        #{notification.data.lead_name}
      </Typography>
      <Typography variant="body-small" color={XelaColor.Gray6}>
        processed by Visa Manager
      </Typography>
      <Typography variant="body-small-bold">{notification.data.event_by}</Typography>
    </HStack>
  )
}

export default VisaGrantedNotification
