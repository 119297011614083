import React, { FC, useEffect, useState } from 'react'
import { Button, Divider, HStack, Typography, VStack } from '@/XelaReact'
import { Drawer } from '@mantine/core'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/Store'
import { useForm } from '@inertiajs/react'
import { updateDocumentBelongsTo, updateShowEditUploadDocumentModal } from '@/Store/leadSlice'
import XSelect from '@/Mantine/XSelect'
import XTextInput from '@/Mantine/XTextInput'
import { XTextarea } from '@/Mantine/XTextarea'
import axios from 'axios'
import collect from 'collect.js'
import { DocumentTypeProps } from '@/Types/DocumentTypes'

const EditUploadDocumentsModal: FC<{
  portal?: string
}> = ({ portal = 'Agency' }) => {
  const dispatch = useDispatch()
  const leadStore = useSelector((state: RootState) => state.lead)
  const [leadId, setLeadId] = useState<string | null>(null)
  const [documentTypes, setDocumentTypes] = useState<DocumentTypeProps[]>([])

  const { data, setData, post, errors, reset, recentlySuccessful } = useForm<{
    id: number
    document: File | null
    name: string
    group: string
    type: string
    others_type: string
    entity: string
    entity_id: string | null
    lead_id: string | null
    belongs_to: string
    documents: File[]
    comments: string
  }>({
    id: 1,
    document: null,
    name: '',
    group: '',
    type: '',
    belongs_to: 'Applicant',
    others_type: '',
    entity: 'Lead',
    entity_id: null,
    lead_id: null,
    documents: [],
    comments: '',
  })

  useEffect(() => {
    axios
      .post<DocumentTypeProps[]>('/select/meta-options', {
        entity_name: 'documents',
        column_name: 'lead_documents',
        with_group: true,
      })
      .then((response) => {
        setDocumentTypes(response.data)
      })
      .catch((error) => {
        console.error('Error fetching documents types:', error)
      })
  }, [])

  useEffect(() => {
    if (recentlySuccessful) {
      dispatch(
        updateShowEditUploadDocumentModal({
          show: false,
          document_entity: '',
          document_entity_id: null,
          lead_id: null,
          belongs_to: '',
          document: null,
        })
      )
      reset()
    }
  }, [recentlySuccessful])

  useEffect(() => {
    if (leadStore.show_edit_upload_document_modal) {
      setLeadId(leadStore.lead_id)

      setData((prev) => ({
        ...prev,
        lead_id: leadStore.lead_id,
        entity: leadStore.document_entity,
        entity_id: leadStore.document_entity_id,
        belongs_to: leadStore.document_belongs_to,
        comments: leadStore.document ? leadStore.document.comments : '',
        name: leadStore.document ? leadStore.document.name : '',
        type: leadStore.document ? leadStore.document.type : '',
        id: leadStore.document ? leadStore.document.id : 0,
      }))
    }
  }, [leadStore.show_edit_upload_document_modal])

  return (
    <Drawer
      overlayProps={{
        color: '#000',
        opacity: 0.55,
        blur: 3,
      }}
      position={'right'}
      closeOnEscape={false}
      closeOnClickOutside={false}
      withCloseButton={false}
      trapFocus={false}
      size={700}
      opened={leadStore.show_edit_upload_document_modal}
      onClose={() => {
        dispatch(
          updateShowEditUploadDocumentModal({
            show: false,
            document_entity: '',
            document_entity_id: null,
            lead_id: null,
            belongs_to: '',
            document: null,
          })
        )
        reset()
      }}
      styles={{
        body: {
          height: '100%',
        },
      }}
    >
      <VStack spacing="12px" height={'100%'}>
        <HStack justifyContent="space-between">
          <HStack>
            <Typography variant={'subheadline'}>Edit Documents</Typography>
          </HStack>
          <HStack width="50%" justifyContent="flex-end">
            <XSelect
              value={data.belongs_to}
              size={'sm'}
              onInit={(callback) => {
                axios
                  .post(portal == 'Hub' ? '/select/meta-options' : '/select/meta-options', {
                    entity_name: 'leads',
                    column_name: 'document_upload_belongs_to',
                  })
                  .then((response) => {
                    callback(response.data)
                  })
                  .catch((error) => {
                    console.log(error)
                    callback([])
                  })
              }}
              onChange={(value) => {
                if (value) {
                  setData((prevData) => ({
                    ...prevData,
                    belongs_to: value,
                  }))
                  dispatch(updateDocumentBelongsTo(value))
                }
              }}
            ></XSelect>
          </HStack>
        </HStack>
        <VStack
          className={'pr-2 overflow-y-auto'}
          spacing={'12px'}
          style={{
            flex: '1 1 0',
            height: 0,
          }}
        >
          <VStack spacing="12px">
            <HStack>
              <Divider variant={'dotted'}></Divider>
            </HStack>
            <HStack spacing="12px" style={{ minHeight: '240px' }}>
              <HStack spacing="12px" alignItems="flex-start">
                <VStack spacing="12px" alignItems="flex-end">
                  <XTextInput
                    label="Name"
                    value={data.name}
                    error={errors['name']}
                    onChange={(e) => {
                      if (e.target) {
                        setData((prevData) => ({
                          ...prevData,
                          name: e.target.value,
                        }))
                      }
                    }}
                  ></XTextInput>
                  <XSelect
                    searchable
                    label={'Type'}
                    error={errors.type}
                    value={data.type}
                    data={documentTypes}
                    onChange={(value) => {
                      if (value) {
                        const datum = collect(documentTypes).where('value', value).first()
                        if (datum) {
                          setData((prevData) => ({
                            ...prevData,
                            group: datum.group,
                            type: value,
                          }))
                        }
                      }
                    }}
                  />

                  {data.type === 'Others' && (
                    <XTextInput
                      label="Others Type"
                      error={errors['others_type']}
                      value={data.others_type}
                      onChange={(e) => {
                        if (e.target) {
                          setData((prevData) => ({
                            ...prevData,
                            others_type: e.target.value,
                          }))
                        }
                      }}
                    ></XTextInput>
                  )}
                  <XTextarea
                    label="Comments"
                    value={data.comments}
                    error={errors['comments']}
                    onChange={(e) => {
                      if (e.target) {
                        setData((prevData) => ({
                          ...prevData,
                          comments: e.target.value,
                        }))
                      }
                    }}
                  ></XTextarea>
                </VStack>
              </HStack>
            </HStack>
          </VStack>
        </VStack>
        <HStack>
          <Divider variant="dotted"></Divider>
        </HStack>
        <HStack spacing="12px" justifyContent="flex-end">
          <Button
            style={{ width: '80px' }}
            label={'Cancel'}
            variant={'secondary'}
            onClick={() => {
              dispatch(
                updateShowEditUploadDocumentModal({
                  show: false,
                  document_entity: '',
                  document_entity_id: null,
                  lead_id: null,
                  belongs_to: '',
                  document: null,
                })
              )
              reset()
            }}
          ></Button>
          <Button
            disabled={leadId === null}
            style={{ width: '80px' }}
            label="Upload"
            onClick={() => {
              post(`/documents/${data.id}/edit`)
            }}
          ></Button>
        </HStack>
      </VStack>
    </Drawer>
  )
}

export default EditUploadDocumentsModal
