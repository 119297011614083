import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export interface GlobalState {
  isSidebarOpen: boolean
  isSubSidebarOpen: boolean
  isCoachingSubSidebarOpen: boolean
  showSearch: boolean
  showCourseModal: boolean
  showDocumentEditor: boolean
  showDocumentViewer: boolean
  documentUrl?: string
  documentName?: string
  documentMimeType?: string
  documentType?: string
  documentLeadId?: string
  isB2BCommissionSubSidebarOpen: boolean
  isB2BSettingSubSidebarOpen: boolean
  isHubCommissionSubSidebarOpen: boolean
  isLoginAsNewUser: boolean
}

const initialState: GlobalState = {
  isSidebarOpen: false,
  isSubSidebarOpen: false,
  isCoachingSubSidebarOpen: false,
  showSearch: false,
  showCourseModal: false,
  showDocumentEditor: false,
  showDocumentViewer: false,
  documentUrl: undefined,
  documentName: undefined,
  documentMimeType: undefined,
  documentType: undefined,
  documentLeadId: undefined,
  isB2BCommissionSubSidebarOpen: false,
  isB2BSettingSubSidebarOpen: false,
  isHubCommissionSubSidebarOpen: false,
  isLoginAsNewUser: false,
}

export const globalSlice = createSlice({
  name: 'globalSlice',
  initialState,
  reducers: {
    toggleSidebar(state, action: PayloadAction<boolean>) {
      state.isSidebarOpen = action.payload
    },
    toggleSubSidebar(state, action: PayloadAction<boolean>) {
      state.isSubSidebarOpen = action.payload
    },
    toggleCoachingSubSidebar(state, action: PayloadAction<boolean>) {
      state.isCoachingSubSidebarOpen = action.payload
    },
    updateShowSearch(state, action: PayloadAction<boolean>) {
      state.showSearch = action.payload
    },
    updateShowCourseModal(state, action: PayloadAction<boolean>) {
      state.showCourseModal = action.payload
    },
    updateShowDocumentEditor(
      state,
      action: PayloadAction<{
        show: boolean
        documentType?: string
        documentLeadId?: string
      }>
    ) {
      state.showDocumentEditor = action.payload.show
      state.documentType = action.payload.documentType
      state.documentLeadId = action.payload.documentLeadId
    },
    updateShowDocumentViewer(
      state,
      action: PayloadAction<{
        show: boolean
        documentUrl?: string
        documentName?: string
        documentMimeType?: string
      }>
    ) {
      state.showDocumentViewer = action.payload.show
      state.documentUrl = action.payload.documentUrl
      state.documentName = action.payload.documentName
      state.documentMimeType = action.payload.documentMimeType
    },
    toggleB2BCommissionSubSidebar(state, action: PayloadAction<boolean>) {
      state.isB2BCommissionSubSidebarOpen = action.payload
    },
    toggleB2BSettingSubSidebar(state, action: PayloadAction<boolean>) {
      state.isB2BSettingSubSidebarOpen = action.payload
    },
    toggleHubCommissionSubSidebar(state, action: PayloadAction<boolean>) {
      state.isHubCommissionSubSidebarOpen = action.payload
    },
    updateIsLoginAsNewUser(state, action: PayloadAction<boolean>) {
      state.isLoginAsNewUser = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  toggleSidebar,
  toggleSubSidebar,
  toggleCoachingSubSidebar,
  updateShowSearch,
  updateShowCourseModal,
  updateShowDocumentEditor,
  updateShowDocumentViewer,
  toggleB2BCommissionSubSidebar,
  toggleB2BSettingSubSidebar,
  toggleHubCommissionSubSidebar,
  updateIsLoginAsNewUser,
} = globalSlice.actions

export default globalSlice.reducer
