import React, { FC, useEffect, useState } from 'react'
import { Box, Checkbox, Drawer, Group, useMantineTheme } from '@mantine/core'
import { Divider, HStack, Typography, VStack, XelaColor } from '@/XelaReact'
import { useDebouncedState, useHotkeys } from '@mantine/hooks'
import XTextInput from '@/Mantine/XTextInput'
import { IconSearch, IconX } from '@tabler/icons-react'
import useFlash from '@/Hooks/useFlash'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/Store'
import { updateShowSearch } from '@/Store/globalSlice'
import { router } from '@inertiajs/react'
import SearchCard from '@/Layouts/Components/SearchCard'

interface SearchResult {
  lead_id: number
  entity: string
  entity_id: number
  lead_info?: string
  course_info?: string
  document_info?: string
  status: string
}

const GlobalSearchModal: FC<{ portal?: string }> = ({ portal = 'Agency' }) => {
  const theme = useMantineTheme()
  const dispatch = useDispatch()

  const globalState = useSelector((state: RootState) => state.global)

  const flash = useFlash()
  const [debouncedSearch, setDebouncedSearch] = useDebouncedState<string | null>(null, 1000)
  const [exactMatch, setExactMatch] = useState<boolean>(false)
  const [searchResults, setSearchResults] = useState<SearchResult[]>([])
  const [searchIncludes, setSearchIncludes] = useState<string[]>(
    portal === 'Agency'
      ? ['Visa', 'Admission Application', 'Counselling', 'Lead', 'Visitor Lead', 'IELTS Lead']
      : ['Lead', 'Admission Application', 'Visa']
  )

  useEffect(() => {
    if (flash.action === 'global_search') {
      const payload = flash.payload as SearchResult[]
      setSearchResults(payload)
    }
  }, [flash.timestamp])

  useHotkeys([['mod+S', () => dispatch(updateShowSearch(!globalState.showSearch))]])

  useEffect(() => {
    if (debouncedSearch !== null) {
      portal === 'Agency' || portal === 'Hub'
        ? router.post(
            '/global/search',
            {
              exact_match: exactMatch,
              search: debouncedSearch,
              includes: searchIncludes,
            },
            { preserveState: true, preserveScroll: true }
          )
        : router.post(
            '/b2b/global/search',
            {
              exact_match: exactMatch,
              search: debouncedSearch,
              includes: searchIncludes,
            },
            { preserveState: true, preserveScroll: true }
          )
    }
  }, [debouncedSearch])

  return (
    <>
      <Box
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          width: '36px',
          height: '36px',
          padding: 0,
          display: 'none',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '36px',
          cursor: 'pointer',
          boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
          backgroundColor: XelaColor.White,
        }}
        onClick={() => {
          dispatch(updateShowSearch(!globalState.showSearch))
        }}
      >
        <IconSearch color={XelaColor.Blue3} size={18}></IconSearch>
      </Box>
      <Drawer
        size={700}
        overlayProps={{
          color: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2],
          opacity: 0.55,
          blur: 3,
        }}
        closeOnEscape={false}
        closeOnClickOutside={false}
        withCloseButton={false}
        opened={globalState.showSearch}
        onClose={() => {
          dispatch(updateShowSearch(!globalState.showSearch))
        }}
        styles={{
          body: {
            height: '100%',
          },
        }}
      >
        <VStack height="100%" spacing="12px">
          <HStack>
            <HStack spacing="12px">
              <Typography variant={'subheadline'}>Global Search</Typography>
              <Checkbox
                checked={exactMatch}
                value="exact_match"
                label={<Typography variant="caption">Exact Match</Typography>}
                color="indigo"
                size="sm"
                onChange={() => {
                  setExactMatch(!exactMatch)

                  portal === 'Agency' || portal === 'Hub'
                    ? router.post(
                        '/global/search',
                        {
                          exact_match: !exactMatch,
                          search: debouncedSearch,
                          includes: searchIncludes,
                        },
                        { preserveState: true, preserveScroll: true }
                      )
                    : router.post(
                        '/b2b/global/search',
                        {
                          exact_match: !exactMatch,
                          search: debouncedSearch,
                          includes: searchIncludes,
                        },
                        { preserveState: true, preserveScroll: true }
                      )
                }}
              />
            </HStack>
            <HStack width="initial">
              <Box
                style={{
                  padding: '4px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  dispatch(updateShowSearch(!globalState.showSearch))
                }}
              >
                <IconX size={16} color={XelaColor.Gray7}></IconX>
              </Box>
            </HStack>
          </HStack>
          <HStack>
            <Divider variant="dotted"></Divider>
          </HStack>
          <HStack>
            <XTextInput
              data-autofocus
              value={debouncedSearch}
              onKeyUp={(event) => {
                setDebouncedSearch(event.currentTarget.value)
              }}
              label="Search..."
              icon={<IconSearch size={20} />}
            ></XTextInput>
          </HStack>
          <HStack justifyContent="center">
            <Checkbox.Group
              value={searchIncludes}
              onChange={(value) => {
                if (value.length) {
                  if (portal === 'Agency' || portal === 'Hub') {
                    value =
                      portal === 'Agency'
                        ? value.find((item) => item === 'Lead') &&
                          value.find((item) => item !== 'Visitor Lead') &&
                          value.find((item) => item !== 'IELTS Lead')
                          ? [...value, 'Visitor Lead', 'IELTS Lead']
                          : value.filter((item) => item !== 'Visitor Lead' && item !== 'IELTS Lead')
                        : value

                    setSearchIncludes(value)

                    router.post(
                      '/global/search',
                      {
                        exact_match: exactMatch,
                        search: debouncedSearch,
                        includes: value,
                      },
                      { preserveState: true, preserveScroll: true }
                    )
                  } else {
                    router.post(
                      '/b2b/global/search',
                      {
                        exact_match: exactMatch,
                        search: debouncedSearch,
                        includes: value,
                      },
                      { preserveState: true, preserveScroll: true }
                    )
                  }
                }
              }}
            >
              <Group mt="xs">
                <Checkbox value="Lead" label="Lead" color="indigo" />
                {portal === 'Agency' && <Checkbox value="Counselling" label="Counselling" color="indigo" />}
                {portal === 'Agency' && (
                  <Checkbox value="Admission Application" label="Admission Application" color="indigo" />
                )}
                {portal === 'Agency' && <Checkbox value="Visa" label="Visa" color="indigo" />}
                <Checkbox value="Document" label="Document" color="indigo" />
              </Group>
            </Checkbox.Group>
          </HStack>
          <Divider variant="dotted"></Divider>
          <VStack
            spacing="12px"
            style={{
              flex: '1 1 auto',
              height: 0,
              overflowY: 'auto',
              padding: '0 8px',
            }}
          >
            {searchResults.map((result, key) => {
              return <SearchCard key={key} result={result} portal={portal}></SearchCard>
            })}
          </VStack>
        </VStack>
      </Drawer>
    </>
  )
}

export default GlobalSearchModal
