import React, { FC, useEffect, useState } from 'react'
import { Button, Divider, HStack, Typography, VStack, XelaColor } from '@/XelaReact'
import { Chip, Drawer, Group, LoadingOverlay } from '@mantine/core'
import { router, useForm, usePage } from '@inertiajs/react'
import XMultiSelect from '@/Mantine/XMultiSelect'
import XTextInput from '@/Mantine/XTextInput'
import moment from 'moment'
import useMeta from '@/Hooks/useMeta'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/Store'
import {
  updateIsTaskModalOpen,
  updateSelectedTaskIeltsLead,
  updateSelectedTaskInsuranceLead,
  updateSelectedTaskLead,
  updateSelectedTaskVisitorLead,
  updateTaskEntity,
  updateTaskEntityId,
} from '@/Store/taskSlice'
import { AttachmentType } from '@/Types/TemplateTypes'
import ReactRedactorX from '@/RedactorX/ReactRedactorX'
import useFlash from '@/Hooks/useFlash'
import XDateTimePicker from '@/Mantine/XDateTimePicker'

const getDueDate = (due_date_time: string, custom_due_date_time: string) => {
  switch (due_date_time) {
    case 'today':
      return moment().set({ hour: 18, minute: 0, second: 0, millisecond: 0 }).toDate()
    case 'tomorrow':
      return moment().add(1, 'days').set({ hour: 18, minute: 0, second: 0, millisecond: 0 }).toDate()
    case 'day_after_tomorrow':
      return moment().add(2, 'days').set({ hour: 18, minute: 0, second: 0, millisecond: 0 }).toDate()
    case 'next_week':
      return moment().add(1, 'weeks').set({ hour: 18, minute: 0, second: 0, millisecond: 0 }).toDate()
    case 'next_month':
      return moment().add(1, 'months').set({ hour: 18, minute: 0, second: 0, millisecond: 0 }).toDate()
    case 'custom':
      if (custom_due_date_time === 'tomorrow') {
        return moment().add(1, 'days').set({ hour: 18, minute: 0, second: 0, millisecond: 0 }).toDate()
      } else {
        return moment(custom_due_date_time, 'YYYY-MM-DD HH:mm:ss').toDate()
      }
  }
}

const CreateTaskModal: FC = () => {
  const meta = useMeta()
  const flash = useFlash()
  const page = usePage()
  const dispatch = useDispatch()
  const taskStore = useSelector((state: RootState) => state.task)

  const [loading, setLoading] = useState(false)
  const lead = taskStore.selected_task_lead !== null ? taskStore.selected_task_lead : null
  const visitorLead = taskStore.selected_task_visitor_lead !== null ? taskStore.selected_task_visitor_lead : null
  const ieltsLead = taskStore.selected_task_ielts_lead !== null ? taskStore.selected_task_ielts_lead : null
  const insuranceLead = taskStore.selected_task_insurance_lead !== null ? taskStore.selected_task_insurance_lead : null
  const entity = taskStore.task_entity !== null ? taskStore.task_entity : null
  const entityId = taskStore.task_entity_id !== null ? taskStore.task_entity_id : null

  const [description] = useState('')
  const [attachments] = useState<AttachmentType[]>([])

  const [counsellors, setCounsellors] = useState<{ value: string; label: string; group: string }[]>([])

  const { data, setData, post, reset, errors, clearErrors, recentlySuccessful } = useForm<{
    lead_id: number | null
    lead_type: string | null
    entity: string | null
    entity_id: number | null
    task: string
    description: string
    assignees: string[]
    due_date_time: string
    custom_due_date_time: string
    attachments: AttachmentType[]
  }>({
    lead_id: lead
      ? lead.id
      : visitorLead
      ? visitorLead.id
      : ieltsLead
      ? ieltsLead.id
      : insuranceLead
      ? insuranceLead.id
      : null,
    lead_type: lead
      ? `Lead`
      : visitorLead
      ? `Visitor Lead`
      : ieltsLead
      ? `IELTS Lead`
      : insuranceLead
      ? `Insurance Lead`
      : null,
    entity: entity !== null ? entity : null,
    entity_id: entityId !== null ? entityId : null,
    task: '',
    description: '',
    assignees: [],
    due_date_time: 'tomorrow',
    custom_due_date_time: 'tomorrow',
    attachments: [],
  })

  useEffect(() => {
    setData((prev) => {
      return {
        ...prev,
        lead_id: lead
          ? lead.id
          : visitorLead
          ? visitorLead.id
          : ieltsLead
          ? ieltsLead.id
          : insuranceLead
          ? insuranceLead.id
          : null,
        lead_type: lead
          ? `Lead`
          : visitorLead
          ? `Visitor Lead`
          : ieltsLead
          ? `IELTS Lead`
          : insuranceLead
          ? `Insurance Lead`
          : null,
        entity: entity ? entity : null,
        entity_id: entityId ? entityId : null,
      }
    })
  }, [lead, visitorLead, ieltsLead, entity, entityId])

  const formHandler = (name: string, value: string | string[] | AttachmentType[]) => {
    setData((prev) => {
      const datum: { [key: string]: string | string[] | AttachmentType[] } = {}
      datum[name] = value

      return {
        ...prev,
        ...datum,
      }
    })
  }

  const closeHandler = (taskId?: number) => {
    dispatch(updateSelectedTaskLead(null))
    dispatch(updateSelectedTaskVisitorLead(null))
    dispatch(updateSelectedTaskIeltsLead(null))
    dispatch(updateSelectedTaskInsuranceLead(null))
    dispatch(updateTaskEntity(null))
    dispatch(updateTaskEntityId(null))
    dispatch(updateIsTaskModalOpen(!taskStore.is_task_modal_open))
    if (taskId) {
      let url = window.location.href
      if (url.search('task_id') !== -1) {
        url = url.toString().replace(/task_id=\d+/, `task_id=${taskId}`)
      } else {
        url = url.toString()
      }

      router.visit(url, { preserveScroll: true })
    } else {
      router.visit(page.url, { preserveScroll: true })
    }

    clearErrors()
    reset()
  }

  useEffect(() => {
    if (recentlySuccessful) {
      const payload = flash.payload as {
        task_id: number
      } | null

      closeHandler(payload?.task_id)
    }
  }, [recentlySuccessful])

  useEffect(() => {
    if (lead) {
      const taskCounsellors = meta.branchesUsers[lead.branch_id].users.map((counsellor) => {
        let isCounsellorEnabled = false

        // Case 1: Person Have Lead Access
        if (
          counsellor.role.permissions.includes('task_module_manage_tasks') &&
          (counsellor.role.permissions.includes('lead_module_all_leads') ||
            counsellor.role.permissions.includes('counselling_module_all_leads') ||
            counsellor.role.permissions.includes('admission_module_all_leads') ||
            counsellor.role.permissions.includes('visa_module_all_leads'))
        ) {
          isCounsellorEnabled = true
        }

        // Case 2: Person whose counsellor id is same as lead's counsellor id
        if (
          counsellor.role.permissions.includes('task_module_manage_tasks') &&
          (counsellor.role.permissions.includes('lead_module_manage_lead') ||
            counsellor.role.permissions.includes('lead_module_branch_leads') ||
            counsellor.role.permissions.includes('lead_module_all_leads')) &&
          counsellor.id === lead.counsellor_id
        ) {
          isCounsellorEnabled = true
        }

        // Case 4: If Lead have Application than lead.counselling.counsellor_id === counsellor.id
        if (
          lead.counselling &&
          counsellor.role.permissions.includes('task_module_manage_tasks') &&
          (counsellor.role.permissions.includes('lead_module_manage_lead') ||
            counsellor.role.permissions.includes('lead_module_branch_leads') ||
            counsellor.role.permissions.includes('lead_module_all_leads')) &&
          lead.counselling.counsellor_id === counsellor.id
        ) {
          isCounsellorEnabled = true
        }

        // Case 4: If Lead have Application than lead.admission.admission_manager_id === counsellor.id
        if (
          lead.admission &&
          counsellor.role.permissions.includes('task_module_manage_tasks') &&
          (counsellor.role.permissions.includes('lead_module_manage_lead') ||
            counsellor.role.permissions.includes('lead_module_branch_leads') ||
            counsellor.role.permissions.includes('lead_module_all_leads')) &&
          (lead.admission.admission_manager_id === counsellor.id ||
            lead.admission.admission_manager_id_one === counsellor.id ||
            lead.admission.admission_manager_id_two === counsellor.id ||
            lead.admission.admission_manager_id_three === counsellor.id ||
            lead.admission.admission_manager_id_four === counsellor.id ||
            lead.admission.admission_manager_id_five === counsellor.id)
        ) {
          isCounsellorEnabled = true
        }

        // Case 5: If Lead have Visa than lead.visa.visa_manager_id === counsellor.id
        if (
          lead.visa &&
          counsellor.role.permissions.includes('task_module_manage_tasks') &&
          (counsellor.role.permissions.includes('lead_module_manage_lead') ||
            counsellor.role.permissions.includes('lead_module_branch_leads') ||
            counsellor.role.permissions.includes('lead_module_all_leads')) &&
          (lead.visa.visa_manager_id === counsellor.id ||
            lead.visa.visa_manager_id_one === counsellor.id ||
            lead.visa.visa_manager_id_two === counsellor.id ||
            lead.visa.visa_manager_id_three === counsellor.id)
        ) {
          isCounsellorEnabled = true
        }

        return {
          value: counsellor.id.toString(),
          label: counsellor.name,
          group: counsellor.branch.branch_name,
          disabled: !isCounsellorEnabled,
        }
      })

      setCounsellors(taskCounsellors)
    } else {
      setCounsellors(
        meta.counsellors.map((counsellor) => {
          return {
            value: counsellor.id.toString(),
            label: counsellor.name,
            group: counsellor.branch.branch_name,
          }
        })
      )
    }
  }, [lead])

  return (
    <Drawer
      size={700}
      position={'right'}
      overlayProps={{
        color: '#000',
        opacity: 0.55,
        blur: 3,
      }}
      closeOnEscape={false}
      closeOnClickOutside={false}
      withCloseButton={false}
      trapFocus={false}
      opened={taskStore.is_task_modal_open}
      onClose={() => {
        closeHandler()
      }}
      styles={{
        body: {
          height: '100%',
        },
      }}
    >
      <VStack spacing="8px" height="100%">
        <HStack>
          <Typography variant="subheadline">Create Task</Typography>
        </HStack>
        <HStack>
          <Divider variant="dotted"></Divider>
        </HStack>
        <VStack spacing="12px" style={{ position: 'relative' }} height="100%">
          <LoadingOverlay visible={loading} overlayBlur={2} loaderProps={{ color: 'blueX' }} />
          <VStack spacing="12px" style={{ flex: '1 1 auto', height: 0, overflowY: 'auto', paddingRight: '10px' }}>
            <HStack spacing="12px">
              <XTextInput
                label="Task"
                error={errors.task}
                placeholder="Add a task here..."
                value={data.task}
                onChange={(e) => {
                  formHandler('task', e.target.value)
                }}
              ></XTextInput>
            </HStack>
            <HStack>
              <ReactRedactorX
                height={'100px'}
                entity={'tasks'}
                error={errors.description}
                defaultAttachments={attachments}
                defaultValue={description}
                onChange={(content) => {
                  if (content.length > 0 && content !== '<p></p>') {
                    formHandler('description', content)
                  } else {
                    formHandler('description', '')
                  }
                }}
                onFileUpload={(attachments) => {
                  formHandler('attachments', attachments)
                }}
              ></ReactRedactorX>
            </HStack>
            <HStack>
              <XMultiSelect
                searchable
                styles={{
                  input: {
                    minHeight: '120px',
                  },
                  values: {
                    maxHeight: '100px',
                    overflowY: 'auto',
                  },
                }}
                value={data.assignees}
                data={counsellors}
                label="Select Assignees"
                onChange={(value) => {
                  if (value) {
                    formHandler('assignees', value)
                  }
                }}
                error={errors.assignees}
              />
            </HStack>
            <VStack spacing="4px" justifyContent="flex-end">
              <Typography variant="caption" color={XelaColor.Gray6} noWrap={true}>
                Due By
              </Typography>
              <HStack>
                <HStack>
                  <Chip.Group
                    value={data.due_date_time}
                    multiple={false}
                    onChange={(value) => {
                      if (value) {
                        formHandler('due_date_time', value)
                        if (value === 'custom') {
                          formHandler('custom_due_date_time', 'tomorrow')
                        }
                      }
                    }}
                  >
                    <Group spacing={8}>
                      <Chip value="today" size="sm" color="blueX" variant="outline">
                        Today
                      </Chip>
                      <Chip value="tomorrow" size="sm" color="blueX" variant="outline">
                        Tomorrow
                      </Chip>
                      <Chip value="day_after_tomorrow" size="sm" color="blueX" variant="outline">
                        Day After Tomorrow
                      </Chip>
                      <Chip value="next_week" size="sm" color="blueX" variant="outline">
                        Next Week
                      </Chip>
                      <Chip value="next_month" size="sm" color="blueX" variant="outline">
                        Next Month
                      </Chip>
                      <Chip value="custom" size="sm" color="blueX" variant="outline">
                        Custom
                      </Chip>
                    </Group>
                  </Chip.Group>
                </HStack>
                <HStack
                  style={{
                    width: '50%',
                  }}
                >
                  <XDateTimePicker
                    clearable
                    value={getDueDate(data.due_date_time, data.custom_due_date_time)}
                    valueFormat={'DD/MM/YYYY HH:mm:ss'}
                    label="Select Due Date"
                    placeholder="Due Date"
                    onChange={(value) => {
                      if (value) {
                        formHandler(
                          'custom_due_date_time',
                          moment(value).startOf('minute').format('YYYY-MM-DD HH:mm:ss')
                        )
                        formHandler('due_date_time', 'custom')
                      }
                    }}
                  />
                </HStack>
              </HStack>
            </VStack>
          </VStack>
          <HStack>
            <Divider variant="dotted"></Divider>
          </HStack>
          <HStack spacing="12px" justifyContent="flex-end">
            <Button
              label={'Cancel'}
              variant={'secondary'}
              onClick={() => {
                closeHandler()
              }}
            />
            <Button
              label={'Create'}
              onClick={() => {
                setLoading(true)
                post(route('tasks.create'), {
                  preserveState: true,
                  preserveScroll: true,
                  onFinish: () => {
                    setLoading(false)
                  },
                })
              }}
            ></Button>
          </HStack>
        </VStack>
      </VStack>
    </Drawer>
  )
}

export default CreateTaskModal
