import React, { FC, useCallback, useEffect, useState } from 'react'
import { Divider, HStack, TabItem, Tabs, Typography, VStack, XelaColor } from '@/XelaReact'
import { IconBell } from '@tabler/icons-react'
import { Button, Indicator, LoadingOverlay, Popover, UnstyledButton } from '@mantine/core'
import NotificationCard from '@/Components/Notifications/NotificationCard'
import { router } from '@inertiajs/react'
import useAuth from '@/Hooks/useAuth'
import LeadCreatedNotification from '@/Components/Notifications/Components/LeadCreatedNotification'
import CounsellorAssignedNotification from '@/Components/Notifications/Components/CounsellorAssignedNotification'
import LeadConvertedNotification from '@/Components/Notifications/Components/LeadConvertedNotification'
import CounsellingConvertedNotification from '@/Components/Notifications/Components/CounsellingConvertedNotification'
import { notifications as Notifier } from '@mantine/notifications'
import TaskCreatedNotification from '@/Components/Notifications/Components/TaskCreatedNotification'
import TaskCreatedWithLeadNotification from '@/Components/Notifications/Components/TaskCreatedWithLeadNotification'
import CommentCreatedWithLeadNotification from '@/Components/Notifications/Components/CommentCreatedWithLeadNotification'
import CommentCreatedNotification from '@/Components/Notifications/Components/CommentCreatedNotification'
import CommentMentionNotification from '@/Components/Notifications/Components/CommentMentionNotification'
import CommentMentionWithLeadNotification from '@/Components/Notifications/Components/CommentMentionWithLeadNotification'
import AdmissionApplicationAppliedNotification from '@/Components/Notifications/Components/AdmissionApplicationAppliedNotification'
import AdmissionApplicationInterviewPendingNotification from '@/Components/Notifications/Components/AdmissionApplicationInterviewPendingNotification'
import AdmissionApplicationDecisionAwaitedNotification from '@/Components/Notifications/Components/AdmissionApplicationDecisionAwaitedNotification'
import AdmissionApplicationAdmittedNotification from '@/Components/Notifications/Components/AdmissionApplicationAdmittedNotification'
import AdmissionApplicationFeePaidNotification from '@/Components/Notifications/Components/AdmissionApplicationFeePaidNotification'
import VisaScheduledNotification from '@/Components/Notifications/Components/VisaScheduledNotification'
import VisaLodgedNotification from '@/Components/Notifications/Components/VisaLodgedNotification'
import VisaGrantedNotification from '@/Components/Notifications/Components/VisaGrantedNotification'
import VisaDeniedNotification from '@/Components/Notifications/Components/VisaDeniedNotification'
import AdmissionManagerAssignedNotification from '@/Components/Notifications/Components/AdmissionManagerAssignedNotification'
import VisaManagerAssignedNotification from '@/Components/Notifications/Components/VisaManagerAssignedNotification'
import LeadAcceptedNotification from '@/Components/Notifications/Components/LeadAcceptedNotification'
import AgentCommentOnLeadNotification from './Components/AgentCommentOnLeadNotification'
import AgencyCommentOnLeadNotification from '@/Components/Notifications/Components/AgencyCommentOnLeadNotification'
import AgentVisaTransitionNotification from '@/Components/Notifications/Components/AgentVisaTransitionNotification'
import VisitorLeadCreatedNotification from '@/Components/Notifications/Components/VisitorLeadCreatedNotification'
import IeltsLeadCreatedNotification from '@/Components/Notifications/Components/IeltsLeadCreatedNotification'
import axios from 'axios'
import useWidthResize from '@/Hooks/useWidthResize'
import LeadCounsellorAssignedNotification from '@/Components/Notifications/Components/LeadCounsellorAssignedNotification'
import IconBellFill from '@/Icons/IconBellFill'
import PaymentReminderToLeadManagerNotification from '@/Components/Notifications/Components/PaymentReminderToLeadManagerNotification'
import VisitorLeadVisaManagerAssignedNotification from '@/Components/Notifications/Components/VisitorLeadVisaManagerAssignedNotification'
import VisitorLeadVisaManagerChangedNotification from '@/Components/Notifications/Components/VisitorLeadVisaManagerChangedNotification'
import WhatsappMessageReceivedNotification from '@/Components/Notifications/Components/WhatsappMessageReceivedNotification'
import EmailMessageReceivedNotification from '@/Components/Notifications/Components/EmailMessageReceivedNotification'
import InvoiceCreatedNotification from '@/Components/Notifications/Components/InvoiceCreatedNotification'
import InvoiceStatusTransitionNotification from '@/Components/Notifications/Components/InvoiceStatusTransitionNotification'
import InvoiceAcceptedOrRejectedNotification from '@/Components/Notifications/Components/InvoiceAcceptedOrRejectedNotification'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/Store'
import { updateIsLoginAsNewUser } from '@/Store/globalSlice'
import Notification = App.Models.Notification

const Notifications: FC<{ portal?: string }> = ({ portal = 'Agency' }) => {
  const auth = useAuth()
  const width = useWidthResize()
  const dispatch = useDispatch()

  const globalStore = useSelector((state: RootState) => state.global)

  const [tab, setTab] = useState<string>('Unread')
  const [loading, setLoading] = useState<boolean>(false)
  const [unreadNotifications, setUnreadNotifications] = useState([] as Notification[])
  const [readNotifications, setReadNotifications] = useState([] as Notification[])

  const fetchUnreadNotifications = useCallback(() => {
    setLoading(true)
    axios
      .post<{
        success: boolean
        notifications: Notification[]
      }>('/notifications/unread/fetch', {
        portal: portal,
      })
      .then((response) => {
        if (response.data.success) {
          setUnreadNotifications(response.data.notifications)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const fetchReadNotifications = useCallback(() => {
    setLoading(true)
    axios
      .post<{
        success: boolean
        notifications: Notification[]
      }>('/notifications/read/fetch', {
        portal: portal,
      })
      .then((response) => {
        if (response.data.success) {
          setReadNotifications(response.data.notifications)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    fetchUnreadNotifications()
    fetchReadNotifications()
  }, [])

  useEffect(() => {
    if (globalStore.isLoginAsNewUser) {
      fetchUnreadNotifications()
      fetchReadNotifications()

      dispatch(updateIsLoginAsNewUser(false))
    }
  }, [globalStore.isLoginAsNewUser])

  const notificationProcessor = useCallback((notification: Notification) => {
    setUnreadNotifications((prevState) => [notification, ...prevState])

    let notificationJSX = <></>

    switch (notification.type) {
      case 'App\\Notifications\\Hub\\LeadAcceptedNotification':
        notificationJSX = <LeadAcceptedNotification notification={notification} />
        break
      case 'App\\Notifications\\B2B\\AgentCommentOnLeadNotification':
        notificationJSX = <AgentCommentOnLeadNotification notification={notification} />
        break
      case 'App\\Notifications\\Hub\\AgencyCommentOnLeadNotification':
        notificationJSX = <AgencyCommentOnLeadNotification notification={notification} />
        break
      case 'App\\Notifications\\Lead\\LeadCreatedNotification':
        notificationJSX = <LeadCreatedNotification portal={portal} notification={notification} />
        break
      case 'App\\Notifications\\VisitorLead\\VisitorLeadCreatedNotification':
        notificationJSX = <VisitorLeadCreatedNotification portal={portal} notification={notification} />
        break
      case 'App\\Notifications\\IeltsLead\\IeltsLeadCreatedNotification':
        notificationJSX = <IeltsLeadCreatedNotification portal={portal} notification={notification} />
        break
      case 'App\\Notifications\\Lead\\LeadCounsellorAssignedNotification':
        notificationJSX = <LeadCounsellorAssignedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Counselling\\CounsellorAssignedNotification':
        notificationJSX = <CounsellorAssignedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Lead\\LeadConvertedNotification':
        notificationJSX = <LeadConvertedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Counselling\\CounsellingConvertedNotification':
        notificationJSX = <CounsellingConvertedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Task\\TaskCreatedNotification':
        notificationJSX = <TaskCreatedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Task\\TaskCreatedWithLeadNotification':
        notificationJSX = <TaskCreatedWithLeadNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Task\\Comment\\CommentCreatedNotification':
        notificationJSX = <CommentCreatedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Task\\Comment\\CommentCreatedWithLeadNotification':
        notificationJSX = <CommentCreatedWithLeadNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Task\\Comment\\CommentMentionNotification':
        notificationJSX = <CommentMentionNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Task\\Comment\\CommentMentionWithLeadNotification':
        notificationJSX = <CommentMentionWithLeadNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Admission\\AdmissionManagerAssignedNotification':
        notificationJSX = <AdmissionManagerAssignedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Visa\\VisaManagerAssignedNotification':
        notificationJSX = <VisaManagerAssignedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Admission\\AdmissionApplicationAppliedNotification':
        notificationJSX = <AdmissionApplicationAppliedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Admission\\AdmissionApplicationInterviewPendingNotification':
        notificationJSX = (
          <AdmissionApplicationInterviewPendingNotification notification={notification} portal={portal} />
        )
        break
      case 'App\\Notifications\\Admission\\AdmissionApplicationDecisionAwaitedNotification':
        notificationJSX = (
          <AdmissionApplicationDecisionAwaitedNotification notification={notification} portal={portal} />
        )
        break
      case 'App\\Notifications\\Admission\\AdmissionApplicationAdmittedNotification':
        notificationJSX = <AdmissionApplicationAdmittedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Admission\\AdmissionApplicationFeePaidNotification':
        notificationJSX = <AdmissionApplicationFeePaidNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Visa\\VisaScheduledNotification':
        notificationJSX = <VisaScheduledNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Visa\\VisaLodgedNotification':
        notificationJSX = <VisaLodgedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Visa\\VisaGrantedNotification':
        notificationJSX = <VisaGrantedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Visa\\VisaDeniedNotification':
        notificationJSX = <VisaDeniedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Hub\\AgentVisaTransitionNotification':
        notificationJSX = <AgentVisaTransitionNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Payment\\PaymentReminderToLeadManagerNotification':
        notificationJSX = <PaymentReminderToLeadManagerNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\VisitorLead\\VisitorLeadVisaManagerAssignedNotification':
        notificationJSX = <VisitorLeadVisaManagerAssignedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\VisitorLead\\VisitorLeadVisaManagerChangedNotification':
        notificationJSX = <VisitorLeadVisaManagerChangedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Whatsapp\\WhatsappMessageReceivedNotification':
        notificationJSX = <WhatsappMessageReceivedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\Whatsapp\\EmailMessageReceivedNotification':
        notificationJSX = <EmailMessageReceivedNotification notification={notification} portal={portal} />
        break
      case 'App\\Notifications\\B2B\\InvoiceCreatedNotification':
        notificationJSX = <InvoiceCreatedNotification notification={notification} />
        break
      case 'App\\Notifications\\B2B\\InvoiceStatusTransitionNotification':
        notificationJSX = <InvoiceStatusTransitionNotification notification={notification} />
        break
      case 'App\\Notifications\\Hub\\InvoiceAcceptedOrRejectedNotification':
        notificationJSX = <InvoiceAcceptedOrRejectedNotification notification={notification} />
        break
    }

    Notifier.show({
      id: notification.id,
      withCloseButton: false,
      autoClose: 5000,
      title: (
        <HStack
          spacing="3px"
          style={{
            padding: '0 12px',
          }}
        >
          <Typography variant="body-bold">Notification</Typography>
        </HStack>
      ),
      message: notificationJSX,
      color: 'blueX',
      icon: <IconBell size={18} stroke={1.5} />,
      loading: false,
    })
  }, [])

  useEffect(() => {
    if (portal === 'Hub') {
      window.Echo.private('App.Models.Agent.' + auth.user.id).notification((notification: Notification) => {
        notificationProcessor(notification)
      })
    } else {
      window.Echo.private('App.Models.User.' + auth.user.id).notification((notification: Notification) => {
        if (notification.data && notification.data.agent_id) {
          if (portal === 'Agency') {
            return
          }
        }

        notificationProcessor(notification)
      })
    }
  }, [])

  return (
    <Popover width={440} position="left-start" withArrow shadow="lg" radius={16} arrowOffset={20}>
      <Popover.Target>
        <UnstyledButton>
          <Indicator
            size={18}
            label={
              <Typography variant="sub-caption" style={{ fontSize: '9px' }}>
                {unreadNotifications.length > 9 ? '9+' : unreadNotifications.length}
              </Typography>
            }
            disabled={unreadNotifications.length === 0}
            color={XelaColor.Orange3}
            offset={3}
          >
            <HStack bg={XelaColor.Blue12} borderRadius="15px" style={{ padding: '12px' }} width="initial">
              <IconBellFill className={'blue-3 p-[4px]'} width={'28'} height={'30'} />
            </HStack>
          </Indicator>
        </UnstyledButton>
      </Popover.Target>
      <Popover.Dropdown style={{ padding: 16 }}>
        <LoadingOverlay visible={loading} loaderProps={{ color: XelaColor.Blue3 }} />
        <VStack spacing="12px" style={{ minHeight: width > 1280 ? '600px' : '450px' }}>
          <HStack justifyContent="space-between">
            <Typography variant="subheadline" color={XelaColor.Gray3}>
              Notifications
            </Typography>
            <Button
              size={'xs'}
              variant="white"
              color={'blueX'}
              radius="12px"
              onClick={() => {
                router.post('/notifications/mark-all-read')
                fetchReadNotifications()
                fetchUnreadNotifications()
              }}
            >
              Mark all as read
            </Button>
          </HStack>
          <HStack>
            <Divider variant="dotted"></Divider>
          </HStack>
          <Tabs
            style={{
              width: '100%',
              borderBottomWidth: '2px',
              borderBottomStyle: 'solid',
              borderBottomColor: XelaColor.Gray12,
            }}
            autoresize={false}
            items={[new TabItem('Unread', 'Unread'), new TabItem('Read', 'Read')]}
            name="notification_tabs"
            defaultValue={tab}
            onChangeHandle={(value) => {
              setTab(value)
              if (value === 'Read') {
                fetchReadNotifications()
              } else {
                fetchUnreadNotifications()
              }
            }}
          ></Tabs>
          {tab === 'Unread' && (
            <VStack
              spacing="8px"
              style={{
                flex: '1 1 auto',
                height: 0,
                overflowY: 'auto',
              }}
            >
              {unreadNotifications.length > 0 &&
                unreadNotifications
                  .filter(
                    (notification) =>
                      !['App\\Notifications\\Insurance\\InsuranceOrderPaidNotification'].includes(notification.type)
                  )
                  .map((notification, index) => {
                    return (
                      <VStack
                        spacing="12px"
                        key={notification.id}
                        onClick={() => {
                          setTimeout(() => {
                            axios.post(`/notifications/${notification.id}/mark-read`).then(() => {
                              setUnreadNotifications((prevNotifications) =>
                                prevNotifications.filter((prevNotification) => prevNotification.id !== notification.id)
                              )
                            })
                          }, 500)
                        }}
                      >
                        <NotificationCard portal={portal} notification={notification} />
                        {index !== unreadNotifications.length - 1 && (
                          <HStack>
                            <Divider></Divider>
                          </HStack>
                        )}
                      </VStack>
                    )
                  })}
              {unreadNotifications.length === 0 && (
                <VStack height="100%" alignItems="center" justifyContent="center">
                  <Typography variant="body" color={XelaColor.Gray6}>
                    No Notifications yet
                  </Typography>
                </VStack>
              )}
            </VStack>
          )}
          {tab === 'Read' && (
            <VStack
              spacing="8px"
              style={{
                flex: '1 1 auto',
                height: 0,
                overflowY: 'auto',
              }}
            >
              {readNotifications.length > 0 &&
                readNotifications
                  .filter(
                    (notification) =>
                      !['App\\Notifications\\Insurance\\InsuranceOrderPaidNotification'].includes(notification.type)
                  )
                  .map((notification, index) => {
                    return (
                      <VStack spacing="12px" key={notification.id}>
                        <NotificationCard portal={portal} notification={notification} disableRead={true} />
                        {index !== readNotifications.length - 1 && (
                          <HStack>
                            <Divider></Divider>
                          </HStack>
                        )}
                      </VStack>
                    )
                  })}
              {readNotifications.length === 0 && (
                <VStack height="100%" alignItems="center" justifyContent="center">
                  <Typography variant="body" color={XelaColor.Gray6}>
                    No Notifications yet
                  </Typography>
                </VStack>
              )}
            </VStack>
          )}
        </VStack>
      </Popover.Dropdown>
    </Popover>
  )
}

export default Notifications
